<template>
  <div class="home-rank home-list" id="homeRankPlanType">
    <slot name="tab"></slot>
    <van-sticky :offset-top="stickyTop">
      <div id="homeRankSticky">
        <div>
          <van-dropdown-menu active-color="#151d36">
            <van-dropdown-item v-model="query.payType" :options="options.payTypes"/>
            <van-dropdown-item v-model="query.days" :options="options.days"/>
            <van-dropdown-item v-model="query.rankTypes" :options="options.rankTypes"/>
          </van-dropdown-menu>
        </div>
        <div class="rank-types">
          <div @click="scrollToRankType(item,index)" :class="{active: tab===item.value}" class="rank-type-item"
               v-for="(item,index) in rankTypes"
               :key="item.value">{{ item.text }}
          </div>
        </div>
      </div>
    </van-sticky>

    <van-pull-refresh :value="refreshing" @refresh="loadRanks">
      <div v-for="(item,index) in currRanks" :key="index" class="rank-panel" :id="item.id">
        <div :class="'rank-title ' + (index === 0?'rank-title-1':'')">{{ item.title }}</div>
        <template v-if="item.rankArr && item.rankArr.length">
          <rank-item @beforeGoDetail="beforeGoDetail" v-for="(rankItem,subIndex) in item.rankArr" :key="subIndex"
                     :item="rankItem"/>
        </template>
        <template v-else>
          <div class="skeleton-block" style="height: 120px;width: 100vw;margin: 0 auto 2px auto;"></div>
          <div class="skeleton-block" style="height: 120px;width: 100vw;margin: 0 auto 2px auto;"></div>
          <div class="skeleton-block" style="height: 120px;width: 100vw;margin: 0 auto;"></div>
        </template>
      </div>
    </van-pull-refresh>

    <div class="end-tip">灵感到底了~</div>

    <div class="pager" v-if="scrolling">
      <div class="pager-curr">
        {{ currCardIndex }}
      </div>
      <div class="pager-total">50</div>
    </div>
    <div class="pager" v-else @click="toTop" style="font-size: 20px">
      <van-icon name="back-top"/>
    </div>
  </div>
</template>

<script>
import {planRankV2Api} from "../../../api/v2/planRankV2Api";
import RankItem from "./rankItem";
import {scrollToDropdownMenu} from "./home";
import {mapMutations} from "vuex";

export default {
  components: {RankItem},
  props: ['stickyTop', 'currTab'],


  mounted() {
    this.styles.swipeWidth = document.body.clientWidth
    document.querySelector('#homeRankPlanType').addEventListener('scroll', this.onScroll)
  },

  beforeDestroy() {
    const elem = document.querySelector('#homeRankPlanType')
    if (elem) {
      elem.removeEventListener('scroll', this.onScroll)
    }
  },

  computed: {

    rankTypes() {
      if (this.query.rankTypes === 'planType') {
        return [
          {text: '活动方案', value: 21},
          {text: '营销方案', value: 22},
          {text: '合作方案', value: 23},
          {text: '运营/设计', value: 24},
          {text: '课件/研报/其他', value: 25},
        ]
      } else if (this.query.rankTypes === 'industryType') {
        return [
          {text: '耐消行业', value: 31},
          {text: '快消行业', value: 32},
          {text: '内容行业', value: 33},
          {text: '服务行业', value: 34},
          {text: '不限行业', value: 35},
        ]
      }
    },


    rankItems() {
      if (this.query.rankTypes === 'planType') {
        return [
          this.getRank(this.query.payType, 21, this.query.days),
          this.getRank(this.query.payType, 22, this.query.days),
          this.getRank(this.query.payType, 23, this.query.days),
          this.getRank(this.query.payType, 24, this.query.days),
          this.getRank(this.query.payType, 25, this.query.days),
        ]
      } else if (this.query.rankTypes === 'industryType') {
        return [
          this.getRank(this.query.payType, 31, this.query.days),
          this.getRank(this.query.payType, 32, this.query.days),
          this.getRank(this.query.payType, 33, this.query.days),
          this.getRank(this.query.payType, 34, this.query.days),
          this.getRank(this.query.payType, 35, this.query.days),
        ]
      }
    },


    currRanks() {
      const currRanks = []
      for (const rankType of this.rankTypes) {
        const rankArr = this.getRank(this.query.payType, rankType.value, this.query.days)
        const title = rankType.text
        const id = `r-${this.query.payType}-${this.query.days}-${rankType.value}`
        currRanks.push({
          rankArr, title, id
        })
      }
      return currRanks
    }

  },

  watch: {

    query: {
      deep: true,
      handler(newVal, oldVal) {
        scrollToDropdownMenu(0, '#homeRankPlanType')
      }
    },

    'query.rankTypes'() {
      this.tab = this.rankTypes[0].value
    }
  },

  data() {
    return {
      refreshing: false,
      scrolling: false,
      currCardIndex: 1,
      styles: {
        swipeWidth: 390
      },
      hasInit: false,
      ranks: {
        all: []
      },
      query: {
        payType: 1,
        days: 1,
        rankTypes: 'planType'
      },
      tab: 21,
      options: {
        payTypes: [
          {text: '会员免费', value: 1},
          {text: '会员折扣', value: 2}
        ],
        days: [
          {text: '日榜', value: 1},
          {text: '周榜', value: 7},
          {text: '月榜', value: 30},
        ],
        rankTypes: [
          {text: '按类型分', value: 'planType'},
          {text: '按行业分', value: 'industryType'},
        ]
      },
    }
  },

  activated() {
    document.querySelector('#homeRankPlanType').scrollTop = this.$store.state.scrollPosition.home.rankPlanType;
  },


  methods: {

    toTop() {
      document.querySelector('#homeRankPlanType').scrollTo({top: 0, behavior: 'smooth'})
    },

    init() {
      if (!this.hasInit) {
        this.loadRanks()
      }
      this.hasInit = true
    },


    beforeGoDetail() {
      const scrollTop = document.querySelector('#homeRankPlanType').scrollTop
    },


    async loadRanks() {
      try {
        this.refreshing = true
        this.ranks.all = await planRankV2Api.findAll()
      } finally {
        this.refreshing = false
      }
    },


    onScroll(e) {
      if (!this.scrolling) {
        this.scrolling = true
        setTimeout(() => {
          this.scrolling = false
        }, 1000)
      }
      this.$store.commit('scrollPosition/SET_HOME_SCROLL', {rankPlanType: e.target.scrollTop})
      const $rankTypesRect = document.querySelector('.rank-types').getBoundingClientRect()
      const $rankPanels = document.getElementsByClassName('rank-panel')
      let currPanel = $rankPanels[0]

      for (let i = 0; i < $rankPanels.length; i++) {
        const $panel = $rankPanels[i]
        const $panelRect = $panel.getBoundingClientRect()
        if ($panelRect.top <= $rankTypesRect.bottom + 8) {
          currPanel = $panel
        } else {
          break
        }
      }
      const split = currPanel.id.split('-')
      this.tab = parseFloat(split[split.length - 1])


      const $rankItems = document.getElementsByClassName('rank-item')
      let currCardIndex = 1
      for (let i = 0; i < $rankItems.length; i++) {
        const $rankItem = $rankItems[i]
        const rect = $rankItem.getBoundingClientRect()
        if (rect.top < $rankTypesRect.bottom) {
          currCardIndex = i + 1
        } else {
          break
        }
      }
      this.currCardIndex = currCardIndex || 1

    },


    scrollToRankType(item) {
      const $dom = document.querySelector(`#r-${this.query.payType}-${this.query.days}-${item.value}`)
      const $rankTypesRect = document.querySelector('#homeRankSticky').getBoundingClientRect()
      scrollToDropdownMenu($dom.offsetTop - $rankTypesRect.height, '#homeRankPlanType')
    },


    // 获取rankItems
    getRank(payType, rankType, days) {
      return this.ranks.all.filter((r) =>
        r.rankType === payType &&
        r.days === days &&
        r.rangeType === rankType
      ).slice(0, 10)
    },

  }

}
</script>

<style lang="less" scoped>
@import "./home-list.less";

.home-rank {
  padding-bottom: 12px;
}

.rank-types {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 27px;
  font-size: 12px;
  color: #999;
  background: #f4f4f4;
}

.rank-type-item {
  color: #999;
}

.rank-type-item.active {
  color: #000;
}

.rank-panel {
  background: #fff;
}

.rank-title {
  background: #fff;
  padding: 12px 27px;
  font-weight: bolder;
  font-size: 14px;
  margin-top: 12px;
}

.rank-title-1 {
  margin-top: 0;
}

.end-tip {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999;
  padding-top: 12px;
  padding-bottom: 12px;
}

</style>
<!--<style>-->
<!--.home-rank .van-swipe-item{-->
<!--  width: 100vw !important;-->
<!--}-->
<!--</style>-->

<style lang="less">
.home-rank .van-dropdown-item__content {
  position: absolute;
}
</style>
