<template>
  <div class="home-rank home-list" id="homeRankCollect">
    <slot name="tab"></slot>
    <van-sticky :offset-top="stickyTop">
      <van-dropdown-menu active-color="#151d36">
        <van-dropdown-item @change="loadData({isRefresh: true})" :options="[
          {text:'近一周',value:7},
          {text:'近一月',value:30},
          {text:'近三月',value:90},
        ]" v-model="query.rankRange"/>
      </van-dropdown-menu>
    </van-sticky>

    <van-pull-refresh :value="refreshing" @refresh="loadData({isRefresh: true})" loading-text="灵感加载中">

      <van-list :finished="pageData.current * pageData.pageSize >= pageData.total" loading-text="灵感加载中" :value="refreshing || loading" finished-text="灵感到底了~" @load="loadData">
        <div class="hot-score-rank-item" v-for="(item,index) in pageData.records">
          <rank-item :key="index" :item="item"/>
        </div>
      </van-list>
    </van-pull-refresh>
    <div class="skeleton-block" style="height: 120px;width: 100vw;margin: 0 auto 2px auto;" v-for="i in 20" v-if="!pageData.records || !pageData.records.length"
         :key="i"></div>

    <div class="pager" v-if="scrolling">
      <div class="pager-curr">
        {{ currCardIndex }}
      </div>
      <div class="pager-total">100</div>
    </div>
    <div class="pager" v-else @click="toTop" style="font-size: 20px">
      <van-icon name="back-top"/>
    </div>
  </div>
</template>

<script>
import vCase from '@/components/case.vue'
import {homeSearchV2Api} from "../../../api/v2/homeSearchV2Api";
import RankItem from "./rankItem";
import {scrollToDropdownMenu} from "./home";
import {planSO2rankItem} from "../../../config/util";
import {planHotScoreRankV2Api} from "../../../api/v2/planHotScoreRankV2Api";
import {planCollectRankV2Api} from "../../../api/v2/planCollectRankV2Api";

export default {
  props: ['stickyTop'],
  components: {RankItem, vCase},

  data() {
    return {
      query: {
        rankRange: 7
      },
      scrolling: false,
      refreshing: false,
      loading: false,
      pageData: {
        current: 0,
        pageSize: 10,
        records: [],
        total: 100
      },
      records: [],
      hasInit: false,
      currCardIndex: 1
    }
  },


  activated() {
    document.querySelector('#homeRankCollect').scrollTop = this.$store.state.scrollPosition.home.rankHotScore;
  },

  mounted() {
    document.querySelector('#homeRankCollect').addEventListener('scroll', this.onScroll)
  },

  beforeDestroy() {
    const elem = document.querySelector('#homeRankCollect')
    if (elem) {
      elem.removeEventListener('scroll', this.onScroll)
    }
  },


  methods: {

    async init() {
      if (!this.hasInit) {
        await this.loadData({isRefresh: true})
        this.hasInit = true
      }
    },


    async loadData(e) {
      if (this.refreshing || this.loading) {
        return
      }
      e = e || {}
      const isRefresh = e.isRefresh
      const current = isRefresh ? 1 : e.current || this.pageData.current + 1
      const pageSize = this.pageData.pageSize
      const total = this.pageData.total
      // 单个列表已经加载完成
      if (!isRefresh && (current-1) * pageSize >= total) {
        return
      }

      try {
        if (isRefresh) {
          this.refreshing = true
        } else {
          this.loading = true
        }

        const res = await planCollectRankV2Api.search({
          current,
          pageSize,
          size: pageSize,
          rankRange: this.query.rankRange
        })
        res.records = res.records.map(planSO2rankItem)
        if (isRefresh) {
          this.pageData.current = 1
          this.pageData.records = res.records
        } else {
          this.pageData.current = current
          this.pageData.records = [...this.pageData.records, ...res.records]
        }

        for(let i = 0;i<this.pageData.records.length;i++){
          this.pageData.records[i].rankIndex = i+1
        }

      } finally {
        this.refreshing = false
        this.loading = false
      }
    },


    onScroll(e) {
      if (!this.scrolling) {
        this.scrolling = true
        setTimeout(() => {
          this.scrolling = false
        }, 1000)
      }
      this.$store.commit('scrollPosition/SET_HOME_SCROLL', {rankHotScore: e.target.scrollTop})

      const rankItems = document.querySelectorAll('#homeRankCollect .hot-score-rank-item')
      for (let i = 0; i < rankItems.length; i++) {
        const item = rankItems[i]
        const rect = item.getBoundingClientRect()
        if (rect.top > this.stickyTop + 60) {
          this.currCardIndex = i + 1
          break
        }
      }
    },


    toTop() {
      scrollToDropdownMenu(0, '#homeRankCollect')
    },
  }

}
</script>

<style scoped lang="less">
@import "home-list";

.home-rank {
  padding-bottom: 12px;
  max-height: calc(100vh - 50px - @tabbar-height);
  overflow-y: auto;
}


</style>
