<template>
  <div>
    <home-rank-hot-score ref="hotScore" :sticky-top="headerHeight" v-show="subTab==='hotScore'">
      <template #tab>
        <home-rank-sub-tab :tab.sync="subTab" @change="onSubTabChange"/>
      </template>
    </home-rank-hot-score>

    <home-rank-plan-type ref="planType" :sticky-top="headerHeight" v-show="subTab==='planType'">
      <template #tab>
        <home-rank-sub-tab ref="homeRankSubTab" :tab.sync="subTab" @change="onSubTabChange"/>
      </template>
    </home-rank-plan-type>

    <home-rank-recommeded ref="recommended" :sticky-top="headerHeight" v-show="subTab==='recommended'">
      <template #tab>
        <home-rank-sub-tab :tab.sync="subTab" @change="onSubTabChange"/>
      </template>
    </home-rank-recommeded>

    <home-rank-collect ref="collect" :sticky-top="headerHeight" v-show="subTab==='collect'">
      <template #tab>
        <home-rank-sub-tab :tab.sync="subTab" @change="onSubTabChange"/>
      </template>
    </home-rank-collect>

  </div>
</template>

<script>
import HomeRankSubTab from "./homeRankSubTab";
import HomeRankPlanType from "./homeRankPlanType";
import HomeRankHotScore from "./homeRankHotScore";
import HomeRankRecommeded from "./homeRankRecommeded";
import HomeRankCollect from "./homeRankCollect";
import {sleep} from "../../../config/util";

export default {
  components: {HomeRankCollect, HomeRankRecommeded, HomeRankHotScore, HomeRankPlanType, HomeRankSubTab},

  data() {
    return {
      subTab: '',
      headerHeight: ''
    }
  },


  async mounted() {
    this.init()
    while(true) {
      const homeHeader = document.getElementById('home-header')
      if(homeHeader) {
        this.headerHeight = homeHeader.getBoundingClientRect().height
        break
      }
      await sleep(200)
    }
  },


  methods: {


    init() {
      const subTab = this.$route.query.subTab || 'collect'
      this.$refs.homeRankSubTab.switchTab(subTab)
    },


    onSubTabChange(tab) {
      this.subTab = tab
      const rank = this.$refs[tab]
      if (rank && rank.init) {
        rank.init()
      }
    }

  }
}
</script>

<style scoped>

</style>
