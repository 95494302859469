import {v2Request} from "./v2Request";

export const homeSearchV2Api = {

  // 首页查询
  async search(params) {
    // 将原参数转换为新参数
    // const parsedParams = {
    //   current: params.current,
    //   pageSize: params.size
    // };
    //
    // // 免费类型，会员免费、免费、付费、全部
    // if (params.freeTypes) {
    //   parsedParams.freeTypes = params.freeTypes;
    // }
    // if (params.planCategory && params.planCategory.length) {
    //   const freeTypeEnum = {
    //     'FREE': 1,
    //     'MEMBER_FREE': 2,
    //     'PAY': 3
    //   };
    //   parsedParams.freeType = freeTypeEnum[params.planCategory] || null;
    // }
    //
    // // 方案分类，如 方案类型、活动类型等 plan_category表中的字段，即首页上方标签条件
    // const categoryKeEnum = {
    //   'projPlanType': 'projPlanType',
    //   'activityType': 'activityType',
    //   'placeType': 'placeType',
    //   'industryType': 'industryType',
    //   'enjoyAmount': 'joinText',
    //   'projectBudget': 'budgetText'
    // };
    // Object.keys(categoryKeEnum).forEach(key => {
    //   const val = params[key];
    //   if (val && val.length) {
    //     const toKey = categoryKeEnum[key];
    //     parsedParams[toKey] = params[key];
    //   }
    // });


    // // 右侧筛选
    // if (params.containFields && params.containFields.length) {
    //   parsedParams.isRecommended = params.containFields.includes('GOODS_RECOMMEND') ? true : null;
    //   parsedParams.isMyFocus = params.containFields.includes('MY_FOCUS') ? true : null;
    //   parsedParams.isHasPlace = params.containFields.includes('CONTAIN_PLACE') ? true : null;
    //   parsedParams.isInDiscount = params.containFields.includes('CONTAIN_DISCOUNT') ? true : null;
    //   parsedParams.isHasBrank = params.containFields.includes('CONTAIN_BRANK') ? true : null;
    //   parsedParams.isInCollection = params.containFields.includes('STATISTICS_DEMAND') ? true : null;
    //   ['isRecommened', 'isMyFocus', 'isHasPlace', 'isInDiscount', 'isHasBrank', 'isInCollection'].forEach(key => {
    //     if (!parsedParams[key]) {
    //       delete parsedParams[key];
    //     }
    //   })
    // }
    //
    // // 关键字
    // if (params.keyword && params.keyword.length) {
    //   parsedParams.keyword = params.keyword;
    // }
    //
    // // 排序
    // if (params.sortField && params.sortField.length) {
    //   const sortEnum = {
    //     'NEW_UPLOAD': {orderBy: 'createTime', order: 'desc'},
    //     'MORE_BROWSE': {orderBy: 'viewTimes', order: 'desc'},
    //     'MORE_RECOMMEND': {orderBy: 'recommendTimes', order: 'desc'},
    //     'MORE_COLLECT': {orderBy: 'collectTimes', order: 'desc'},
    //     'RANDOM_BROWSE': {orderBy: 'random', order: 'desc'}
    //   };
    //   const sort = sortEnum[params.sortField];
    //   if (sort) {
    //     parsedParams.orderBy = sort.orderBy;
    //     parsedParams.order = sort.order;
    //   }
    // }

    // return v2Request.get(`homeSearch/search`, {params: parsedParams});
    params.projPlanType = params.planType;

    return v2Request.get(`search-svr/plan/search`, {params});
  },


  /**
   * 查询方案关联的其他方案
   */
  async findPlanRef(planId, current, size) {
    return v2Request.get(`search-svr/plan/findPlanRef`, {params: {planId, current, size, pageSize: size}});
  }


};
