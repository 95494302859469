import {v2Request} from "./v2Request";

export const recommendedRankApi = {

  findIssues() {
    return v2Request.get(`search-svr/recommendedRank/findIssues`)
  },


  findIssue(query) {
    return v2Request.post(`search-svr/recommendedRank/findIssue`, query)
  }

}
