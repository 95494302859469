<template>
  <div :class="'rank-item rank-item-'+item.rankIndex + ` ${hideIndex?'hide-index':''}`" @click="goPlan">
    <div class="cover">
      <img :src="item.planCover" alt="">
    </div>
    <div class="info">
      <div class="title">{{ item.planTitle }}</div>
      <div class="creator">
        <img class="avatar" :src="item.createAvatar" alt="">
        <div class="username">{{ item.createUsername }}</div>
      </div>
    </div>
    <div class="rank-index" v-if="!hideIndex">{{ item.rankIndex }}</div>
  </div>
</template>

<script>
export default {
  props: ['item','hideIndex'],
  methods:{
    goPlan(){
      this.$emit('beforeGoDetail')
      this.$router.push('/caseDetail/'+this.item.planId)
    }
  }
}
</script>

<style scoped>

.rank-item {
  display: flex;
  align-items: center;
  background: #fff;
  padding: 12px 27px;
  border-bottom: 1px solid #e9e9e9;
}

.cover {
  flex-shrink: 0;
  height: 68px;
}

.cover img {
  width: 120px;
  height: 68px;
  border-radius: 6px;
}

.info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  padding: 0 8px;
  height: 60px;
  width: calc(100vw - 54px - 120px - 24px - 30px);
}

.hide-index .info{
  width: calc(100vw - 54px - 120px - 24px);
}

.title{
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.creator {
  display: flex;
  align-items: center;
}

.avatar {
  height: 12px;
  width: 12px;
  border-radius: 100px;
}

.username {
  font-size: 12px;
  color: #999;
  padding-left: 2px;
}

.rank-index {
  width: 30px;
  height: 30px;
  border-radius: 100px;
  border: 2px solid #fff;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bolder;
  font-size: 16px;
  font-style: italic;
}

.rank-item-1 .rank-index{
  border-color: #e44c4c;
}

.rank-item-1 .title{
  color: #e44c4c;
}

.rank-item-2 .rank-index{
  border-color: #fc744c;
}

.rank-item-2 .title{
  color: #fc744c;
}

.rank-item-3 .rank-index{
  border-color: #fcc44c;
}

.rank-item-3 .title{
  color: #fcc44c;
}

</style>
