import {v2Request} from "./v2Request";
import axios from 'axios'
import {sleep} from "../../config/util";


const proms = {}

setInterval(() => {
  for(const key in proms){
    proms[key] = null
  }
}, 2 * 60 * 60 * 1000)

export const planRankV2Api = {

  async findAll() {
    if (!proms.findAll) {
      proms.findAll = v2Request.get('/search-svr/planRank/findAll')
    }
    return proms.findAll
  },


  async findTodayRank() {
    if(!proms.findTodayRank){
      proms.findTodayRank = v2Request.get('/search-svr/plan/findTodayRank')
    }
    return proms.findTodayRank
  },

}
