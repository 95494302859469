import {v2Request} from "./v2Request";


export const planCollectRankV2Api = {

  search(query) {
    return v2Request.post(`/search-svr/planCollectRank/search`, query)
  }

}
