import {v2Request} from "./v2Request";


export const planHotScoreRankV2Api = {

  search(query) {
    return v2Request.post(`/search-svr/planHotScoreRank/search`, query)
  }

}
